import React from 'react';
import { Element } from 'react-scroll';
import akj from '../../images/akj.jpg';
function TeamsSection() {

  const teamMembers = [
    {
      name: 'Sijin Prakashan',
      position: 'CEO',
      image: 'https://profile-images.xing.com/images/9cde60f66a0d4667d9c67a070599ad77-2/sijin-prakasan.1024x1024.jpg',
    },
    {
      name: 'Abraham Joseph',
      position: 'Head of Mobile Applications',
      image: akj,
    },
    {
      name: 'Jitheesh Vasudevan',
      position: 'Head of Web Application',
      image: 'https://about.me/cdn-cgi/image/q=80,dpr=1,f=auto,fit=cover,w=1200,h=630,gravity=0x0.148/https://assets.about.me/background/users/j/i/t/jithivasudev_1545912810_077.jpg',
    }
    // Add details for other team members
  ];

  return (
    <Element name="team" className="h-screen min-h-screen">
       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
       <h1 className="text-8xl text-white font-bold mb-6">MEET <br></br>OUR <br></br>GALAXY</h1>
        <div className="grid grid-cols-3 gap-8 mt-8">
          {teamMembers.map((member) => (
            <div key={member.name} className="flex flex-col items-center bg-indigo-500 rounded-lg shadow-md">
              <img
                className="rounded-full h-32 w-32 object-cover mb-4"
                src={member.image}
                alt={member.name}
              />
              <h3 className="text-xl font-medium text-gray-900">{member.name}</h3>
              <p className="text-white">{member.position}</p>
              <br></br>
            </div>
          ))}
        </div>
      </div>
      </Element>


  )
}

export default TeamsSection