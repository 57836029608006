import React,{useEffect,useState} from 'react';
import { Element,scroller } from 'react-scroll';
import Navbar from './components/navbars/Navbar';
import HomeSection from './components/sections/HomeSection';
import AboutusSection from './components/sections/AboutusSection';
import TeamsSection from './components/sections/TeamsSection';
const App = () => {

  const [lineHeight, setLineHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById('about');
      const windowHeight = window.innerHeight;
      const aboutSectionHeight = aboutSection ? aboutSection.clientHeight : 0;
      const initialLineHeight = windowHeight / 2 - aboutSectionHeight / 2;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const newLineHeight = initialLineHeight + scrollTop;
      setLineHeight(newLineHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };
  return (
    <div className='bg-black'>
      <div className='horizontal-line-absolute-container'>

          <div className='horizontal-line-wrapper'>
            <div className='horizontal-line'></div>
            <div className='horizontal-line'></div>
            <div className='horizontal-line'></div>
          </div>
      </div>
      <Navbar />
      
      <HomeSection/>
      <AboutusSection/>
      <TeamsSection/>
      {/* <Element name="services" className="h-screen min-h-screen">
      <h1 className="text-center pt-20 text-white text-5xl"><b className='text-8xl'>S</b>ervices</h1>
      </Element>
      <Element name="team" className="h-screen min-h-screen">
      <h1 className="text-center pt-20 text-white text-5xl"><b className='text-8xl'>T</b>eam</h1>
      </Element>
     */}
    </div>
  );
};

export default App;
