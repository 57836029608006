import React from 'react'

import { Element } from 'react-scroll';
export default function HomeSection() {
  return (
    <Element name="home" className="relative home-section min-h-screen">
     
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
    <div className="space">
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
      </div>
      <div className="w-1/2 mt-20">
        <h1 className="text-8xl text-white font-bold mb-6">WE <br></br> BUILD YOUR DREAM TO REALITY.</h1>
        <p className="text-xl md:text-2xl text-gray-700 mb-8">We deliver innovative solutions for your business.</p>
        <a href="#contact" className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 px-8 rounded-lg shadow-lg">Contact Us</a>
        
       
      </div>
      <div className="w-1/2 flex justify-center">

      {/* <svg viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
  <path d="M220,427.61008417440786C254.97892081611423,419.2874822645229,275.7156296060934,384.3157084841575,299.0979312142978,357.00163563675204C316.54144124874136,336.6249716205774,325.9024934226357,312.11162700875065,338.94005317026705,288.6700717152821C351.7470109692303,265.64313912998307,367.60456226581545,245.201627724713,375.29453966352673,220C385.1033433605009,187.8545454152437,400.48838805715644,153.82741141444438,389.8095213096844,121.96042716089227C378.8606874277231,89.28782948157283,352.1933717807309,55.80584205313343,318.2613587503633,49.80633422361829C282.5599875308545,43.49398676683232,256.1275766801851,88.89806831105952,220,91.93646272023517C188.58107957921584,94.57885063885813,160.33510879565404,54.42799729573865,130.83915138927603,65.56888016026903C102.55383995563221,76.25249137950843,103.52794874110177,118.01058372012159,85.91034988541284,142.58330441080025C65.8357015403819,170.5830750257811,24.158263795170942,185.78509584170104,20.1185863196022,219.99999999999994C16.140934907127082,253.6895612771159,50.42486276094985,278.75931217218175,66.29221788236995,308.74322938215386C82.84172056973354,340.01617403152716,87.72101788999164,378.99068886578897,115.71006684460563,400.6354629431048C144.60702347234945,422.9823493537058,184.46239412341737,436.0656150953313,220,427.61008417440786" fill="#8A3FFC" />
</svg> */}
      </div>
    </div>
  </Element>
  )
}
