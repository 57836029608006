import React,{useState} from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  

  return (
    <nav className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <h2 className="text-white font-bold text-3xl">Uprabbit<b className='text-purple-800'>.</b></h2>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <ScrollLink
                to="home"
                smooth={true}
                spy={true}
                offset={-70}
                duration={500}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </ScrollLink>
              <ScrollLink
                to="about"
                smooth={true}
                spy={true}
                offset={-70}
                duration={500}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                About Us
              </ScrollLink>
              {/* <ScrollLink
                to="services"
                smooth={true}
                spy={true}
                offset={-70}
                duration={500}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Services
              </ScrollLink> */}
              <ScrollLink
                to="team"
                smooth={true}
                spy={true}
                offset={-70}
                duration={500}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Team
              </ScrollLink>
            </div>
          </div>
          <div className="md:hidden flex items-center">
           <button
              className="text-gray-300 hover:text-white focus:outline-none focus:text-white"
              onClick={toggleMenu}
            >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden mt-2">
            <ScrollLink
              to="home"
              smooth={true}
              spy={true}
              offset={-70}
              duration={500}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </ScrollLink>
            </div>
        )
        }

      </div>
    </nav>
  );
};

export default Navbar;
