import React from 'react'
import { Element } from 'react-scroll';
export default function AboutusSection() {
  return (
    <Element name="about" className="h-screen min-h-screen">
    
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
   
      <div className="w-1/2  bg-indigo-500 p-20">
        <h3 className="text-2xl text-white  mb-6">
            Welcome to our innovative IT firm, where a talented group of nine senior software developers have united to transform your ideas into reality. With a wealth of experience and expertise in the field,
            we are committed to providing tailored solutions to meet your every need.
            <br></br>
            At our core, we believe in the power of technology to shape the world around us. We are passionate about harnessing its potential to bring your visions to life.
             Whether you require a cutting-edge mobile application, a robust web platform, or a sophisticated enterprise software solution
            , we are here to guide you through every step of the process.
        </h3>
      
       
      </div>
      <div className="w-1/2 p-20">
        <h1 className="text-8xl text-white font-bold mb-6">ABOUT UPRABBIT</h1>
       
        <a href="#contact" className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 px-8 rounded-lg shadow-lg">Contact Us</a>
        
       
      </div>
    
    </div>
  </Element>
  )
}
